const DEFAULT_TIMEOUT = 2000;

const getNextIndex = (index, length) => index === length - 1 ? 0 : index + 1;
const getPrevIndex = (index, length) => index === 0 ? length - 1 : index - 1;

export const getSlideItemClasses = (active, index, length, loop = true) => {
  const classes = [];

  if (
    active - 1 === index || // is previous
    (loop && active === 0 && index + 1 === length) // is last if active = 0
  ) {
    classes.push('prev');
  }

  if (active === index) {
    classes.push('current');
  }

  if (
    active + 1 === index || // is next
    (loop && index === 0 && active + 1 === length) // is next if active = length
  ) {
    classes.push('next');
  }

  return classes.join(' ');
};

const defaultCallback = (slides) => {
  slides.forEach(({ el, className }) => {
    el.classList.remove('prev', 'current', 'next');

    className && el.classList.add(className);
  });
};

const makeSlides = (slides, callback, startedIndex = 0) => {
  const state = {
    timeoutId: null
  };

  const setActive = (active) => {
    state.active = active;

    onChange();
  };

  const onChange = () => {
    if (state.timeoutId) {
      clearTimeout(state.timeoutId);
    }

    state.timeoutId = setTimeout(() => {
      state.timeoutId = null;

      setActive(getNextIndex(state.active, slides.length));
    }, slides[state.active]?.timeout || DEFAULT_TIMEOUT);

    const slidesState = slides.map((item, index) => ({
      ...item,
      className: getSlideItemClasses(state.active, index, slides.length)
    }));

    defaultCallback(slidesState);
    callback?.(slidesState, state);
  };

  const goNext = () => setActive(getNextIndex(state.active, slides.length));
  const goPrev = () => setActive(getPrevIndex(state.active, slides.length));
  const goTo = (i) => setActive(i);

  setActive(startedIndex);

  return {
    goNext,
    goPrev,
    goTo
  };
};

export default makeSlides;
