import eachElement from './eachElement';

export default (selector, event, callback, base = document) => {
  eachElement(
    selector,
    el => {
      el.addEventListener(event, callback);
    },
    base
  );
}
