const STATE_STORAGE_KEY = '__cart';
const AUTO_OPEN_KEY = '__cart_auto_open';

export const storage = {
  isEmpty: () => !localStorage.getItem(STATE_STORAGE_KEY),
  get: () => JSON.parse(localStorage.getItem(STATE_STORAGE_KEY) ?? '{}'),
  set: values => localStorage.setItem(STATE_STORAGE_KEY, JSON.stringify(values)),
  clean: () => {
    localStorage.removeItem(STATE_STORAGE_KEY);
    localStorage.removeItem(AUTO_OPEN_KEY);
  },

  autoOpen: (val) => localStorage.setItem(AUTO_OPEN_KEY, val),
  shouldAutoOpen: () => localStorage.getItem(AUTO_OPEN_KEY) === 'true'
};
