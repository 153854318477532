import { loadStripe } from '@stripe/stripe-js';
import style from './style.scss';

export const COUNTRY = fetch('https://api.country.is')
  .then(resp => resp.json())
  .catch(() => ({}));
export const STRIPE_INSTANCE = loadStripe(STRIPE_PUBLIC);

export const STRIPE_APPEARANCE = {
  theme: 'stripe',
  rules: [ ...document.styleSheets ]
    .filter(styleSheet => !styleSheet.href || styleSheet.href.startsWith(window.location.origin))
    .flatMap(styleSheet => [ ...styleSheet.cssRules ])
    .filter(rule => rule.selectorText?.startsWith(`.${style.stripeStyles}`))
    .reduce((acc, rule) => ({
      ...acc,

      [rule.selectorText.replace(`.${style.stripeStyles} `, '')]: rule
        .cssText
        .replace(/.+{ ([^}]+); }/, '$1')
        .split('; ')
        .map(rule => rule.split(':'))
        .reduce((acc, [ key, value ]) => ({
          ...acc,
          [key.replace(/-[a-z]/gi, k => k[k.length - 1].toUpperCase())]: value.trim()
        }), {})
    }), {})
};

export const STEPS = {
  basic: 'basic',
  billing: 'billing',
  payment: 'payment',
  // summary: 'summary',
  // attr: 'attr'
};

export const INTERESTS = {
  'business-interest': 'Business',
  'personal-interest': 'Personal'
};
