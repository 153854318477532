import LazyLoad from 'vanilla-lazyload';
import addListener from './util/addListener';
import checkoutUi from './util/checkoutUi';
import eachElement from './util/eachElement';
import makeInfoSlider from './util/makeInfoSlider';
import makeLearningSystemSlider from './util/makeLearningSystemSlider';
import makeSlides from './util/makeSlides';
import scrollIntoView from './util/scrollIntoView';
import gtmPush, { collectUtmTags, gtmCartPush } from './util/gtmPush';

addListener('a', 'click', (e) => {
  const container = e.target?.closest?.('[data-block]');

  if (container) {
    gtmPush({
      event: 'Click',
      action: `${container.dataset.block}|${e.target.dataset.title || e.target.innerText || 'logo'}`.toLowerCase(),
      label: location.toString()
    });
  }
});

window.addEventListener('message', event => {
  if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    gtmPush({
      event: 'Form Submission',
      action: event.data.id,
      label: location.toString()
    });
  }

  if (event.data.meetingBookSucceeded) {
    gtmPush({
      event: 'Form Submission',
      action: 'meeting',
      label: location.toString()
    });
  }
});

const actions = {
  header: block => {
    const { location } = window;

    addListener('a[href*="\\#"]', 'click', (e) => {
      try {
        const url = new URL(e.target.href);
        const target = document.querySelector(url.hash);

        if (target) {
          e.preventDefault();

          scrollIntoView(target, block.offsetHeight);
        }
      } catch (e) {

      }
    });

    window.addEventListener('hashchange', () => {
      try {
        const target = document.querySelector(location.hash);

        if (target) {
          scrollIntoView(target, block.offsetHeight);
        }
      } catch (e) {
      }
    });

    addListener('button', 'click', ({ currentTarget }) => {
      block.classList.toggle('active');
      currentTarget.classList.toggle('active');

      document.body.style.overflow = block.classList.contains('active') ? 'hidden' : 'initial';
    }, block);

    const url = location.toString().replace(/(#|\?).*/, '');
    eachElement(`a[href="${url}"]`, (el) => {
      el.classList.add('active');

      if (el.parentElement.classList.contains('sub-menu')) {
        el.parentElement.previousElementSibling.classList.add('active');
      }

    });

    addListener('.with-child', 'mouseenter', ({ target }) => {
      const {
        bottom,
        right,
        width
      } = target.getBoundingClientRect();

      target.nextElementSibling.style.top = `${bottom - 10}px`;
      target.nextElementSibling.style.left = `${right - width / 2}px`;
    }, block);

    window.addEventListener('scroll', () => {
      if (window.scrollY && !block.classList.contains('sticky')) {
        block.classList.add('sticky');
      }

      if (!window.scrollY && block.classList.contains('sticky')) {
        block.classList.remove('sticky');
      }
    });
  },
  hero: block => {
    const items = [ ...block.querySelectorAll('span > span') ].map(el => ({
      timeout: el.dataset.timeout,
      el
    }));

    makeSlides(items);
  },
  skills: block => {
    const items = [ ...block.querySelectorAll('span > span') ].map(el => ({
      timeout: 2000,
      el
    }));

    makeSlides(items, () => {
    }, 1);
  },
  testimonials: block => {
    const buttons = [ ...block.querySelectorAll('.pages button:not(.arrow)') ];
    const items = [ ...block.querySelectorAll('.slide') ].map((el, i) => ({
      timeout: el.dataset.timeout * 99999,
      el
    }));

    const actions = makeSlides(items, (_, { active }) => {
      buttons.forEach((el, i) => {
        el.classList.remove('current');

        if (i === active) {
          el.classList.add('current');
        }
      });
    });

    buttons.forEach((el, index) => {
      el.addEventListener('click', () => {
        actions.goTo(index);
      });
    });

    addListener('.arrow-prev', 'click', actions.goPrev);
    addListener('.arrow-next', 'click', actions.goNext);
  },
  blogSlider: block => {
    const container = block.querySelector('.items');
    const items = block.querySelectorAll('.items > *');
    const pages = block.querySelectorAll('.pages span');

    if (items?.length) {
      pages.forEach(el => {
        el.addEventListener('click', () => {
          const target = block.querySelector(`#${el.dataset.id}`);
          if (target) {
            pages.forEach(p => p.classList.remove('current'));

            el.classList.add('current');
            target.scrollIntoView();
          }
        });
      });

      container.addEventListener('scroll', (e) => {
        items.forEach((el, i1) => {
          if (Math.abs(el.getBoundingClientRect().left - container.getBoundingClientRect().left) < 10) {
            pages.forEach((p, i2) => p.classList[i1 === i2 ? 'add' : 'remove']('current'));
          }
        });
      });
    }
  },
  blogLoadMore: block => {
    block.addEventListener('click', () => {
      const container = document.querySelector('[data-more-list]');
      if (container) {
        block.classList.add('loading');

        fetch(block.dataset.next)
          .then(response => response.text())
          .then(html => {
            const parser = new DOMParser();
            const items = parser.parseFromString(html, 'text/html');

            container.append(...items.querySelectorAll('[data-more-list] .blog-post-card'));

            const nextBtn = items.querySelector('[data-fn="blogLoadMore"]');

            if (nextBtn) {
              block.dataset.next = nextBtn.dataset.next;
            } else {
              block.parentElement.remove();
            }

            block.classList.remove('loading');
          });
      }
    });
  },
  blogSearch: block => {
    addListener('input', 'focus', () => {
      if (block.dataset.init) {
        return;
      }

      block.dataset.init = true;
      block.classList.add('loading');

      import('./util/blog-search')
        .then(({ onSearch }) => onSearch(block));
    }, block);
  },
  blogShare: block => {
    const length = document.querySelectorAll('.blog-single > *:not(figure:first-child, .blog-share)')?.length;

    if (length) {
      block.style.gridRow = `span ${length}`;
    }
  },
  video: block => {
    block.addEventListener('click', () => import('./util/youtube').then(({ youtube }) => youtube(block.dataset.video)));
  },
  blogBack: block => {
    if (!document.referrer || document.referrer.indexOf(location.origin) !== 0 || history.length < 2) {
      return block.setAttribute('hidden', 'hidden');
    }
  },
  amzPay: block => {
    import('./util/checkout/amzCheckout').then(({ checkout }) => checkout(block));
  },
  heroV2: (block) => {
    const video = block.querySelector('video');

    if (video) {
      window.addEventListener('scroll', () => {
        video.play();
      });
    }
  },
  hsForm: (block) => {
    const script = document.createElement("script");

    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.onload = () => {
      const elId = block.id;
      const formId = block.dataset.id;

      const form = hbspt.forms.create({
        region: 'na1',
        portalId: '4803066',
        formInstanceId: `${formId}-${elId}`,
        formId,
        target: `#${elId}`
      });

      if (elId === 'blog-subscribe-form') {
        form.onFormSubmitted(() => {
          const c = document.querySelector('.blog-subscribe-cta h2');
          if (c) c.remove();
        });
      }

      if (elId === 'blog-restricted-form') {
        form.onFormSubmitted(() => {
          location.search = '?hs_vid=0';
        });
      }

      if (elId === 'hsform-widget') {
        form.onReady((form) => {
          const style = document.createElement('style');

          style.innerHTML = `
                fieldset {max-width: 100% !important;}
                .hs-input {width: 100%;}
                .hs-form-field label:not(.hs-error-msg) {text-transform: uppercase;letter-spacing: 1.8px;}
                .hs-form-required {padding-left: 5px;}
                .input {margin-right: 20px !important;}
                textarea {resize: none;height: 150px !important;}
                .hs_error_rollup {display: none;}
                .submitted-message {min-height: 200px;display: flex;align-items: center;justify-content: center;font-weight: 500;font-size: 22px!important;letter-spacing: -.1px;color: #8688A1!important;flex-direction: column;gap:20px;}
                .submitted-message:before {content: '✓';display: block;width: 30px;height: 30px;border-radius: 50%;color: #2ADA90;border: 1px solid #2ADA90;text-align: center;line-height: 30px; }
                .hs-button {letter-spacing: 1.8px;text-transform: uppercase;}
            `;
          form.parentElement.appendChild(style);
        });
      }
    };

    document.body.append(script);
  },
  graph: (block) => {
    import('./util/graph').then(({ graph }) => graph(block));
  },
  paymentMessage: (block) => {
    import('./util/checkout/config')
      .then(({ STRIPE_INSTANCE, COUNTRY }) => Promise.all([ STRIPE_INSTANCE, COUNTRY ]))
      .then(([ stripe, { country = 'US' } ]) => {
        const data = block.dataset;

        stripe
          .elements()
          .create('paymentMethodMessaging', {
            amount: parseInt(data.price) * parseInt(data.quantity) * 100,
            currency: 'USD',
            paymentMethodTypes: [ 'klarna', 'afterpay_clearpay', 'affirm' ],
            countryCode: country,
          })
          .mount(block);
      })
    ;
  },
  glossary: (block) => {
    const menu = document.createElement('nav');

    'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').forEach(letter => {
      const link = document.createElement('a');

      link.href = `#${letter}`;
      link.innerHTML = letter;
      link.ariaDisabled = !document.getElementById(letter);

      menu.append(link);
    });

    block.append(menu);
  }
};

(() => (
  new LazyLoad({
    threshold: 500,
    elements_selector: '.lazy, [data-fn], iframe[data-src]',
    unobserve_entered: true,
    callback_enter: el => {
      const fn = el.dataset.fn;
      if (fn && actions[fn]) {
        actions[fn](el);
      }
    }
  })
))();

document.querySelectorAll('[data-fn="info-slider"]').forEach(makeInfoSlider);
document.querySelectorAll('[data-fn="learning-system"]').forEach(makeLearningSystemSlider);

collectUtmTags();

// const BF_END = dayjs('2023-11-27T23:59:00-08:00');
// const BF_COOKIE_NAME = '__black_friday';
//
// if (!document.cookie?.match(BF_COOKIE_NAME) && dayjs().isBefore(BF_END)) {
//   document.cookie = `${BF_COOKIE_NAME}=1`;
//
//   const popup = document.createElement('div');
//
//   popup.classList.add('black-friday');
//   popup.innerHTML = `
//     <div class="content">
//       <button>
//         <svg width="24" height="24">
//           <use href="#close" />
//         </svg>
//       </button>
//       <h2>
//         <svg width="42" height="38" xmlns="http://www.w3.org/2000/svg">
//           <use href="#logo" id="lg"/>
//         </svg>
//         Black Friday Sale
//       </h2>
//       <p>Get the skills you need. <br/> Get the career you want.</p>
//       <h3>50% off Oji Foundations <br/> <small>Black Friday thru Cyber Monday</small></h3>
//       <a href="/pay" class="button blue" data-force="1" data-promocode="BLACKFRIDAY" data-fn="buyBtn" data-program="foundation" data-level="individual" data-quantity="1">
//         Use code <code>BLACKFRIDAY</code> at Checkout
//       </a>
//       <div class="timer"></div>
//     </div>
//   `;
//
//   document.body.append(popup);
//
//   const timer = popup.querySelector('.timer');
//
//   const pad = (n) => (n < 10 ? '0' + n : n);
//
//   const updateTimer = () => {
//     const seconds = BF_END.diff(dayjs(), 's');
//
//     const days = Math.floor(seconds / 24 / 60 / 60);
//     const hoursLeft = Math.floor((seconds) - (days * 86400));
//     const hours = Math.floor(hoursLeft / 3600);
//     const minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
//     const minutes = Math.floor(minutesLeft / 60);
//     const remainingSeconds = seconds % 60;
//
//     timer.innerHTML = `<time>${pad(days)}</time> day <time>${pad(hours)}</time> hr <time>${pad(minutes)}</time> mins <time>${pad(remainingSeconds)}</time> sec`;
//   };
//
//   setTimeout(
//     () => {
//       popup.classList.add('open');
//
//       popup.addEventListener('click', (e) => {
//         if (e.target.classList.contains('black-friday') || e.target.closest('button, a')) {
//           popup.remove();
//         }
//       });
//
//       setInterval(updateTimer, 1000);
//       updateTimer();
//     },
//     4000
//   );
// }

if (!window.___ignoreDefaultPayUi) {
  checkoutUi();
}

if (window.__view_list) {
  gtmCartPush('view_item', window.__view_list);
}
