import getScrollProgress from './getScrollProgress';
import { getSlideItemClasses } from './makeSlides';
import parseStyleInt from './parseStyleInt';

export default (block) => {
  const lgImage = block.querySelector('.lg-img img');
  const slides = block.querySelector('.slides');
  const items = [...block.querySelectorAll('.slides .slide')];

  const length = items.length;

  const initialize = () => {
    block.style.setProperty('--length', `${length}`);

    const headingHeight = Math.max(
      ...[...block.querySelectorAll('.slides .heading')]
        .map(el => parseStyleInt(el, 'height'))
    );

    block.style.setProperty('--heading-height', `${headingHeight}px`);
  };
  window.addEventListener('resize', initialize);
  requestAnimationFrame(initialize);

  let lastActiveIndex = null;
  window.addEventListener('scroll', () => {
    const { top, height } = slides.getBoundingClientRect();

    const progress = getScrollProgress(length, top * -1, height);
    const activeIndex = Math.floor(progress);

    for (let i = 0; i < length; i++) {
      if (lastActiveIndex !== activeIndex) {
        const classes = getSlideItemClasses(activeIndex, i, length, false);

        items[i].setAttribute('class', `${classes} slide`);
        items[i].setAttribute('data-index', i - activeIndex);

        if (activeIndex === i) {
          lgImage.style.opacity = '.5';
          setTimeout(
            () => {
              lgImage.src = items[i].querySelector('.img img').src;
              lgImage.style.opacity = '1';
            },
            250
          );
        }
      }

      if (i < length - 1) {
        const line = items[i].querySelector('.line');
        const rect = line.getBoundingClientRect();

        if (i < activeIndex) {
          line.style.borderTopWidth = `${rect.height}px`;
        }

        if (i === activeIndex) {
          line.style.borderTopWidth = `${progress % 1 * rect.height}px`;
        }

        if (i > activeIndex) {
          line.style.borderTopWidth = 0;
        }
      }
    }

    lastActiveIndex = activeIndex;
  });
  window.dispatchEvent(new Event('scroll'));
}
