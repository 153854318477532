import { storage } from './checkout/storage';
import eachElement from './eachElement';
import { gtmCartPush } from "./gtmPush";
import { getIn } from "final-form";
import { STEPS } from "./checkout/config";

const handleCheckoutOpen = (e) => {
  storage.autoOpen(true);

  e.preventDefault();
  e.target.classList.add('loading');

  import('./checkout').then(({ checkout }) => checkout(e.target));
};

export default async () => {
  if (window.__payment_page && !window.__continue_payment) {
    gtmCartPush('add_payment_info', {
      currency: "USD",
      value: parseFloat(window.__payment_data.to.replace('$', '')),
      coupon: window.__payment_data.dc,
      payment_type: window.__payment_type,
      items: [
        {
          item_id: window.__payment_data.pr.key,
          item_name: window.__payment_data.pr.title,
          item_variant: window.__payment_data.lv.key,
          price: parseFloat(window.__payment_data.p.replace('$', '')),
          quantity: parseInt(window.__payment_data.q),
        }
      ]
    });
    gtmCartPush('purchase', {
      transaction_id: new URLSearchParams(window.location.search).get('id'),
      currency: "USD",
      value: parseFloat(window.__payment_data.to.replace('$', '')),
      coupon: window.__payment_data.dc,
      items: [
        {
          item_id: window.__payment_data.pr.key,
          item_name: window.__payment_data.pr.title,
          item_variant: window.__payment_data.lv.key,
          price: parseFloat(window.__payment_data.p.replace('$', '')),
          quantity: parseInt(window.__payment_data.q),
        }
      ]
    });

    storage.clean();
  }

  if (!storage.isEmpty() && !document.querySelector('#notification')) {
    const state = storage.get();

    const btn = document.createElement('button');

    btn.id = 'cart-cta-banner';
    btn.innerHTML = `
      Heads up! You have something in <br/> checkout 
      <svg width="11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 11">
        <path d="M10.75 10.87a.45.45 0 0 1 0-.64l4.29-4.28H1.3a.45.45 0 0 1 0-.9h13.73L10.75.76a.45.45 0 0 1 .64-.63l5.05 5.05a.45.45 0 0 1 0 .64l-5.05 5.05a.45.45 0 0 1-.64 0Z" fill="currentColor"/>
      </svg>
    `;

    btn.dataset.program = state.program.key;
    btn.dataset.level = state.level.key;
    btn.dataset.quantity = state.quantity;

    btn.addEventListener('click', handleCheckoutOpen);

    document.getElementById('root').prepend(btn);

    if (storage.shouldAutoOpen() && window.location.pathname.match(/^\/(payment-status|pay)/)) {
      btn.click();
    }
  }

  eachElement('[data-fn="buyBtn"]', block => {
    const data = block.dataset;

    const select = block.previousElementSibling?.querySelector('select');

    if (data.quantity && select) {
      const warning = document.createElement('span');
      warning.classList.add('field-warning');

      block.previousElementSibling.after(warning);

      const save = (el) => {
        if (!el) return;

        data.quantity = el.value;

        const hasError = parseInt(data.quantity) > 99;

        warning.innerHTML = hasError
          ? `
            Please <a target="_blank" href="mailto:casij@ojilifelab.com">contact a Customer Relationship Manager</a> 
            for special pricing on purchases over 100 licenses.
          `
          : '';

        block[hasError ? 'setAttribute' : 'removeAttribute']('disabled', 'disabled');
      };

      save(select);

      select.addEventListener('change', ({ target }) => save(target));
    }

    block.addEventListener('click', handleCheckoutOpen);
  });
};
