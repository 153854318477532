const gtmPush = (data) => {
  if (window.dataLayer && window.location.hostname !== 'localhost') {
    window.dataLayer.push(data);
  }
  window.Attribution?.track(data.event);
};

export default gtmPush;

export function gtmCartPush(event, ecommerce) {
  gtmPush({ ecommerce: null });
  gtmPush({
    event,
    ecommerce
  });
}

export const collectUtmTags = () => {
  try {
    const tags = JSON.parse(localStorage.getItem('___utm_tags')) || {};

    const params = new URLSearchParams(location.search);

    [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'gclid',
      'fbclid',
      'ad_id',
      'ad_name',
      'campaign_id',
      'cta',
      'downloadable',

      // 'utm_source',
      // 'utm_medium',
      // 'utm_campaign',
      // 'utm_content',
      // 'utm_term',
      // 'cta',
      'product_interest',
      'page_name'
    ]
      .forEach((key) => {
        if (params.has(key) && params.get(key)) {
          tags[key] = params.get(key);
        }
      });

    localStorage.setItem('___utm_tags', JSON.stringify(tags));
  } catch (e) {
    console.log(e);
  }
};
