import 'swiped-events';
import addListener from './addListener';
import getScrollProgress from './getScrollProgress';
import makeSlides, { getSlideItemClasses } from './makeSlides';
import parseStyleInt from './parseStyleInt';

export default (block) => {
  if (window.innerWidth < 768) {
    const items = [...block.querySelectorAll('h3')].map(el => ({
      timeout: el.dataset.timeout,
      el
    }));

    const actions = makeSlides(items);

    addListener('.arrows > :first-child', 'click', actions.goPrev, block);
    addListener('.arrows > :last-child', 'click', actions.goNext, block);

    addListener('.slides', 'swiped-left', actions.goNext, block);
    addListener('.slides', 'swiped-right', actions.goPrev, block);

    return;
  }

  const container = block.querySelector('.container');
  const slides = block.querySelector('.slides');
  const items = block.querySelectorAll('.slides h3');

  const length = items.length;

  window.addEventListener('scroll', () => {
    const { top, height } = block.getBoundingClientRect();

    const containerHeight = parseStyleInt(container, 'height') - parseStyleInt(container, 'paddingTop') - parseStyleInt(container, 'paddingBottom');
    const slidesHeight = parseStyleInt(slides, 'height');

    block.style.height = `${containerHeight * length}px`;
    container.style.top = `${(window.innerHeight - containerHeight) / 2 - (containerHeight - slidesHeight) + 90}px`;

    const activeIndex = Math.floor(getScrollProgress(length, top * -1, height - height / length));

    for (let i = 0; i < length; i++) {
      items[i].setAttribute('class', getSlideItemClasses(activeIndex, i, length, false));
    }
  });
  window.dispatchEvent(new Event('scroll'));
}